const Images = {
    bannerbg: require("../src/assets/images/toss/bannerbg.png"),
    connect: require("../src/assets/images/toss/connect.png"),
    eth: require("../src/assets/images/toss/eth.png"),
    bitcoin: require("../src/assets/images/toss/bitcoin.png"),
    secure: require("../src/assets/images/toss/secure.png"),
    hack: require("../src/assets/images/toss/hack.png"),
    key: require("../src/assets/images/toss/key.png"),
    tab1: require("../src/assets/images/toss/tab1.png"),
    tab2: require("../src/assets/images/toss/tab2.png"),
    tab3: require("../src/assets/images/toss/tab3.png"),
    tab4: require("../src/assets/images/toss/tab4.png"),
    tab5: require("../src/assets/images/toss/tab5.png"),
    tab6: require("../src/assets/images/toss/tab6.png"),
    prof: require("../src/assets/images/toss/prof.png"),
    fee: require("../src/assets/images/toss/fee.png"),
    shield: require("../src/assets/images/toss/shield.png"),
    graduate: require("../src/assets/images/toss/graduate.png"),
    excrow: require("../src/assets/images/toss/excrow.png"),
    connect1: require("../src/assets/images/toss/Tossvtoss/Mask Group 2.png"),
    connectx: require("../src/assets/images/toss/Tossvtoss/xmen.png"),
    india: require("../src/assets/images/toss/india.png"),

    crypt1: require("../src/assets/images/toss/crypt1.png"),
    crypt2: require("../src/assets/images/toss/crypt2.png"),
    crypt3: require("../src/assets/images/toss/crypt3.png"),
    crypt4: require("../src/assets/images/toss/crypt4.png"),


    ticket: require("../src/assets/images/toss/ticket.png"),
    orangecoin: require("../src/assets/images/toss/orangecoin.png"),
    tag: require("../src/assets/images/toss/tag.png"),
    fixedprice: require("../src/assets/images/toss/fixedprice.png"),
    fb: require("../src/assets/images/toss/fb.png"),
    email: require("../src/assets/images/toss/email.png"),
    twitter: require("../src/assets/images/toss/twitter.png"),
    cog: require("../src/assets/images/toss/cog.png"),
    user: require("../src/assets/images/toss/user.png"),
    homefaq1:require("../src/assets/images/toss/secure_con_1.png"),
    homefaq2:require("../src/assets/images/toss/invite_cont_2.png"),
    homefaq3:require("../src/assets/images/toss/feed_cont_3.png"),
    btclogo:require("../src/assets/images/toss/btc.png"),
    usdtlogo:require("../src/assets/images/toss/usdt.png"),
    xrplogo:require("../src/assets/images/toss/xrp.png"),
    ethlogo:require("../src/assets/images/toss/ethlogo.png"),
    profill : require("../src/assets/images/prifileimg.png")
}

export default Images