
import axios, { handleResp } from '../config/axios'


export const Getuserp2pcreateorderhooks = async (filterdata) => {
    try {
        // console.log("userId",userId)
        let respData = await axios({
            'url': `/api/get-userp2pcreate-orders`,
            'method': 'get',
            'params' : filterdata
        });
        console.log("respodata",respData.data.count)
        return {status : "success", loading : false, result : respData.data.data, count : respData.data.count };
    } catch (err) {
        console.log("errrrrrrr", err);
        handleResp(err, 'error')
        return { status: "failed" }
    }
}

export const Getuserp2pviewofferhooks = async (filterdata) => {
    try {

        let respData = await axios({
            'url': `/api/get-userp2pviewoffer`,
            'method': 'get',
            'params': filterdata
        })
        if(respData.data.type == "success"){
            console.log("count_data")
            return {status : "success", loading : false, data : respData.data.data, count : respData.data.count };
        }
    } catch (err) {
        console.log("errrrrrrr", err);
        handleResp(err, 'error')
        return { status: "failed" }
    }
}